import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store, history } from './redux/store';
import { client } from './apollo';
import * as serviceWorker from './serviceWorker';
import Landing from './containers/Landing';
import Loader from './components/Loader';

import './normalize.min.css';
import './index.css';
import './bulma.min.css';
import './noty.min.css';
import './modal.css';

const renderApp = Component =>
  render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={<Loader />} persistor={persistor}>
            <ConnectedRouter history={history}>
              <Component/>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </ApolloProvider>,
    document.getElementById('root'),
  );

renderApp(Landing)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
