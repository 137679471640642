import { SET_VIEW_ALL } from './constants';

const initialState = {
  viewAll: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_ALL:
      return {
        ...state,
        viewAll: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;