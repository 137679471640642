import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
  from { transform:rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  position: relative;
  &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #FFBF00;
      animation: ${Spin} .5s linear infinite;
    }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(255,255,255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Loader = ({ text }) => {
  return (
    <Wrapper>
      <Spinner />
      <h3 className='mt-1 is-size-4'>{text}</h3>
    </Wrapper>
  );
};

export default Loader;