import * as constants from './constants';

const initialState = {
  menuItems: [],
  rawItems: [],
  canCheckout: false,
  subtotal: 0,
  total: 0,
  shop: null,
  deliveryCost: 0,
  popup: false,
  conflict: false,
  isCollection: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_TO_BASKET:
      return action.payload;
    case constants.SET_SHOP:
      return {
        ...state,
        shop: action.payload
      };
    case constants.CLEAR_BASKET:
      return {
        ...initialState,
      };
    case constants.REMOVE_ITEM_FROM_BASKET:
      return {
        ...state,
        menuItems: state.menuItems.filter(item => item.basketItemId !== action.payload.id),
        total: state.total - action.payload.amount,
        subtotal: state.subtotal - action.payload.amount,
      };
    case constants.SET_USER_BASKET:
      return {
        ...action.payload,
      }
    case constants.SET_BASKET_CONFLICT:
      return {
        ...state,
        conflict: !state.conflict
      }
    case constants.SET_IS_COLLECTION:
      return {
        ...state,
        isCollection: action.payload
      }
    default:
      return state;
  }
};

export default reducer;