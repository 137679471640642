import * as constants from './constants';

const initialState = {
  id: null,
  requestId: null,
  checksum: null,
  card: null,
  method: constants.NEW_PAYMENT_METHOD,
  submitting: false,
  tokenize: true,
  scheduledOrder: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_3D_DATA:
      return {
        ...state,
        id: action.response.id,
        requestId: action.response.requestId,
        checksum: action.response.checksum,
      };
    case constants.SET_CARD_DATA:
      return {
        ...state,
        card: action.payload,
      };
    case constants.SET_PAYMENT_METHOD:
      return {
        ...state,
        method: action.payload,
      };
    case constants.SUBMITTING_ORDER:
      return {
        ...state,
        submitting: action.payload,
      }
    case constants.CHANGE_REMEMBER_CARD:
      return {
        ...state,
        tokenize: action.payload,
      };
    case constants.CHANGE_SCHEDULED_ORDER:
      return {
        ...state,
        scheduledOrder: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;