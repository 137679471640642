export const SET_3D_DATA = 'SET_3D_DATA';
export const SET_CARD_DATA = 'SET_CARD_DATA';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const NEW_PAYMENT_METHOD = 'NEW_PAYMENT_METHOD';
export const EXISTING_PAYMENT_METHOD = 'EXISTING_PAYMENT_METHOD';
export const MOBICRED_PAYMENT_METHOD = 'MOBICRED_PAYMENT_METHOD';
export const SUBMITTING_ORDER = 'SUBMITTING_ORDER';
export const CHANGE_REMEMBER_CARD = 'CHANGE_REMEMBER_CARD';
export const CHANGE_SCHEDULED_ORDER = 'CHANGE_SCHEDULED_ORDER';
export const AWAITING_PAYMENT = 'AWAITING_PAYMENT';
export const PAY_LATER = 'PAY_LATER';