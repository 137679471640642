import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title, scripts }) => (
  <Helmet>
    <title>Cloud Chefs {title && `- ${title}`}</title>
    <meta
      name='keywords'
      content='cloud chefs, online chefs, food delivery, fast food, takeaway'
    />
    <meta
      name='description'
      content='Cloud Chefs is an online food, order to collect and door-to-door delivery service.'
    />
    {scripts && scripts.map((src, index) => (
      <script key={index} async defer src={src}></script>
    ))}
  </Helmet>
);