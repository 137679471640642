import { reducer as form } from 'redux-form';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage/session';
import basket from '../containers/Menu/reducer';
import location from '../containers/Home/reducer';
import auth from '../containers/Login/reducer';
import summary from '../containers/OrderSummary/reducer';
import verification from '../containers/CreateAccount/reducer';
import shops from '../containers/Shops/reducer';
import orders from '../containers/Orders/reducer';
import contact from '../containers/ContactUs/reducer';

const config = {
  key: 'cloudchefs:shop',
  storage,
  blacklist: ['shops']
}

export const history = createBrowserHistory();

const reducers = {
  router: connectRouter(history),
  form,
  basket,
  location,
  auth,
  summary,
  verification,
  shops: persistReducer(config, shops),
  orders,
  contact,
}

export default combineReducers(reducers);
