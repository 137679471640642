import { SET_USER_TO_VERIFY, SET_VERIFICATION_SUCCESS } from './constants';

const initialState = {
  user: null,
  status: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TO_VERIFY:
      return {
        ...state,
        user: action.payload,
      };
    case SET_VERIFICATION_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;