import { SET_CURRENT_USER, UPDATE_USER_PROFILE, SET_RETURN_URL } from './constants';

const initialState = {
  user: null,
  returnUrl: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profile: JSON.stringify(action.payload),
        }
      };
    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;