import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

const config = {
	key: 'cloudchefs',
  whitelist: ['basket', 'order', 'location', 'auth', 'verification'],
	storage,
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(config, reducers);

export const store = createStore(
  connectRouter(history)(persistedReducer),
  {},
  compose(
    applyMiddleware(thunkMiddleware),
    applyMiddleware(routerMiddleware(history)),
    (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ),
);

export const persistor = persistStore(store);

history.listen(() => {
  window.scrollTo(0, 0);
});