import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Meta from '../../components/Meta';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Section = styled.div`
  -webkit-animation: ${fadeIn} 1s;
  animation: ${fadeIn} 1s;
`;

class Landing extends Component {
  render() {
    return (
      <div>
        <Meta title='Order Food &amp; Groceries Online' />
        <section className='hero is-primary is-fullheight'>
          <div className='hero-body'>
            <Section className='container'>
              <h1 className='title is-2'>
                cloudchefs
              </h1>
              <h2 className='subtitle is-5 has-text-white'>
                Order your groceries online and get them delivered to your door. Coming soon.
              </h2>
            </Section>
          </div>
        </section>
      </div>
    )
  }
}

export default Landing;
